import { isEqual } from 'lodash'
import opentype from 'opentype.js'

import { FontFile, FontFileType } from 'modules/api'

import {
  FontUploadReducerState,
  UploadFontFile,
} from '../fontUploadReducer/types'
import { ParsedFontFile } from '../types'

export const isProbablyItalic = (metadataStrings: string[] = []) => {
  const keywords = ['italic', 'oblique']
  for (const str of metadataStrings) {
    for (const keyword of keywords) {
      if (str.toLowerCase().indexOf(keyword) !== -1) {
        return true
      }
    }
  }
  return false
}

export const assignFontWeight = (metadataStrings: string[] = []) => {
  // Look through strings, in the priority order in which they were supplied
  for (const str of metadataStrings) {
    if (looksLikeMatch(str, ['thin'])) {
      return 100 // THIN
    }
    if (looksLikeMatch(str, ['light', 'lite'], ['extra', 'ultra', 'x'])) {
      return 200 // EXTRA LIGHT
    } else if (looksLikeMatch(str, ['light'])) {
      return 300 // LIGHT
    }
    if (looksLikeMatch(str, ['medium'])) {
      return 500 // MEDIUM
    }
    if (looksLikeMatch(str, ['bold'], ['demi', 'semi'])) {
      return 600 // SEMI BOLD
    } else if (looksLikeMatch(str, ['bold'], ['extra', 'x'])) {
      return 800 // EXTRA BOLD
    } else if (looksLikeMatch(str, ['bold'])) {
      return 700 // BOLD
    }
    if (looksLikeMatch(str, ['black', 'heavy', 'ultra'])) {
      return 900 // BLACK
    }
  }
  // Fallback for fonts named "regular", "italic", "book", "roman"...etc
  return 400 // REGULAR
}

const looksLikeMatch = (
  str: string,
  weightKeywords: string[] = [], // eg. "black" or "heavy"
  weightModifierKeywords: string[] = [] // eg. "ultra", "demi", "semi"
) => {
  // Look through all supplied weight keywords/synonyms
  for (const keyword of weightKeywords) {
    // If there are no modifiers supplied, just run a check
    if (weightModifierKeywords.length === 0) {
      if (str.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
        return true
      }
    } else {
      // Otherwise, look for the presence of BOTH the weight AND the modifier
      for (const modifier of weightModifierKeywords) {
        if (
          str.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 &&
          str.toLowerCase().indexOf(modifier.toLowerCase()) !== -1
        ) {
          return true
        }
      }
    }
  }
  return false
}

export const parseFontFile = async (file: File): Promise<ParsedFontFile> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const arrayBuffer = e.target?.result
      const font = opentype.parse(arrayBuffer)
      if (!font) {
        reject('[parseFontFile] Unable to parse font')
      }
      // Unfortunately there's no typing for results of opentype.parse()
      const optimalSubfamilyName =
        (Object.values(
          font.tables?.name?.preferredSubfamily ??
            font.tables?.name?.fontSubfamily
        )?.[0] as string) ?? ''

      const fullName =
        (Object.values(font.tables?.name?.fullName)?.[0] as string) ?? ''

      const familyName =
        (Object.values(
          font.tables?.name?.preferredFamily ?? font.tables?.name?.fontFamily
        )?.[0] as string) ?? ''

      resolve({
        familyName,
        isItalic: isProbablyItalic([optimalSubfamilyName, fullName]),
        weight: assignFontWeight([optimalSubfamilyName, fullName]),
      })
    }

    reader.onerror = () => {
      // if there was an error, provide default values
      resolve({
        familyName: '',
        isItalic: false,
        weight: 400,
      })
    }
    reader.readAsArrayBuffer(file)
  })
}

// from uppy utils
// https://github.com/transloadit/uppy/blob/b8e1f9c995a365fd8ee042fe66944a3f425091b7/packages/@uppy/utils/src/getFileNameAndExtension.js
const getFileNameAndExtension = (fullFileName: string) => {
  const lastDot = fullFileName.lastIndexOf('.')
  // these count as no extension: "no-dot", "trailing-dot."
  if (lastDot === -1 || lastDot === fullFileName.length - 1) {
    return {
      name: fullFileName,
      extension: undefined,
    }
  }
  return {
    name: fullFileName.slice(0, lastDot),
    extension: fullFileName.slice(lastDot + 1),
  }
}

export const getAcceptedFontExtension = (fullFileName: string) => {
  const { extension } = getFileNameAndExtension(fullFileName)
  if (extension === FontFileType.Otf || extension === FontFileType.Ttf) {
    return extension
  } else {
    // TODO figure out how to better handle missing file types
    return FontFileType.Ttf
  }
}

type ComparisonFont = {
  fontName: string
  fontId: string
  fontFiles: {
    id: string
    name: string
    weight: number
    isItalic: boolean
    fileType: FontFileType
    sourceUrl: string
  }[]
}
export const isFontDirty = (state: FontUploadReducerState) => {
  const { existingFont } = state
  if (!existingFont) {
    return true
  }
  const prevFont: ComparisonFont = {
    fontName: existingFont.name,
    fontId: existingFont.id,
    fontFiles: existingFont.fontFiles!.map((file) => ({
      id: file.id,
      name: file.name,
      weight: file.weight,
      isItalic: file.isItalic,
      fileType: file.fileType,
      sourceUrl: file.sourceUrl!,
    })),
  }
  const nextFont: ComparisonFont = {
    fontName: state.fontName,
    fontId: state.fontId!,
    fontFiles: state.uploadFontFiles.map((uf) => ({
      id: uf.fontFile.id,
      name: uf.fontFile.name,
      weight: uf.fontFile.weight,
      isItalic: uf.fontFile.isItalic,
      fileType: uf.fontFile.fileType,
      sourceUrl: uf.fontFile.sourceUrl!,
    })),
  }
  return !isEqual(prevFont, nextFont)
}

export const getStyleCount = (
  fontFiles: {
    weight: number
    isItalic: boolean
  }[]
) => {
  // Since we allow duplicate files, count the *unique* styles, defined as a combination of weight + style
  // For example: 500 - italic and 500 - not italic count as 2 unique styles
  // file types are not considered, unlike for duplicate font files
  const uniqueStyleObj = fontFiles.reduce<{ [key: string]: boolean }>(
    (acc, file) => {
      const key = [file.weight, file.isItalic].join('__')
      return {
        ...acc,
        [key]: true,
      }
    },
    {}
  )
  return Object.keys(uniqueStyleObj).length
}

export const findDuplicateFontFiles = (fontFiles: UploadFontFile[]) => {
  const duplicateFontFiles = Object.values(
    fontFiles.reduce<{ [key: string]: UploadFontFile[] }>((acc, uf) => {
      const curr = uf.fontFile
      const key = [curr.weight, curr.isItalic, curr.fileType].join('__')
      acc[key] = acc[key] || []
      acc[key].push(uf)
      return acc
    }, {})
  ).reduce<{ [id: string]: boolean }>((acc, curr) => {
    if (curr.length > 1) {
      for (const f of curr) {
        acc[f.uploadId] = true
      }
    }
    return acc
  }, {})
  return duplicateFontFiles
}

export const groupFontsByWeight = (
  fontFiles: UploadFontFile[]
): { [key: string]: UploadFontFile[] } => {
  return fontFiles.reduce<{ [key: string]: UploadFontFile[] }>(
    (acc, current) => {
      const keyValue = current.fontFile.weight
      return {
        ...acc,
        [keyValue]: [...(acc[keyValue] || []), current],
      }
    },
    {}
  )
}

export const getSortedFontWeights = <T>(fontWeights: Array<T>, asc = true) => {
  return fontWeights.sort((a, b) => {
    if (asc) {
      return Number(a) - Number(b)
    } else {
      return Number(b) - Number(a)
    }
  })
}

export const getSortedFontFileWeights = (fontFiles: FontFile[]) => {
  return getSortedFontWeights(
    Array.from(new Set(fontFiles.map((file) => file.weight)))
  )
}

export const findClosestFontWeight = (
  sortedFontWeights: number[],
  startingWeight?: number
): number | undefined => {
  if (startingWeight === undefined) {
    return
  }

  if (startingWeight > sortedFontWeights[sortedFontWeights.length - 1]) {
    return sortedFontWeights[sortedFontWeights.length - 1]
  }

  let minDist = Infinity
  let res = -1

  for (let i = 0; i < sortedFontWeights.length; i++) {
    const currDist = Math.abs(startingWeight - sortedFontWeights[i])
    if (currDist < minDist) {
      minDist = currDist
      res = i
    } else {
      break
    }
  }

  return sortedFontWeights[res]
}

export const shouldBeItalicStyle = (fontFiles?: FontFile[]) => {
  // default to not italic
  if (!fontFiles || fontFiles.length === 0) {
    return false
  }
  // If there are no non-italic fonts, then use italic
  return fontFiles.findIndex((file) => !file.isItalic) === -1
}
