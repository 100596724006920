import {
  Accordion,
  AccordionButton,
  AccordionButtonProps,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  duotone,
  regular,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME_PLURAL } from '@gamma-app/ui'
import { Dispatch, SetStateAction, useCallback } from 'react'

import { CSSinJSFormControl } from 'modules/code_editor/components/CSSinJSFormControl'
import { useFeatureFlag } from 'modules/featureFlags'
import { findClosestFontWeight } from 'modules/fonts/utils/utils'
import {
  DEFAULT_ACCENT_COLOR,
  DEFAULT_BODY_COLOR,
  DEFAULT_BODY_COLOR_DARK,
  DEFAULT_FONTS,
  DEFAULT_FONT_WEIGHTS,
  DEFAULT_HEADING_COLOR,
  DEFAULT_HEADING_COLOR_DARK,
} from 'modules/theming'
import { ContainerStylePanel } from 'modules/tiptap_editor/components/drawers/CardStyleDrawer'
import { BackgroundPanel } from 'modules/tiptap_editor/components/panels/BackgroundPanel'
import { ColorPickerInput } from 'modules/tiptap_editor/components/panels/ColorPanel'
import {
  BackgroundType,
  DEFAULT_DOC_BACKGROUND,
  DEFAULT_THEME_BACKGROUND,
} from 'modules/tiptap_editor/styles/backgroundStyles'
import {
  ContainerEffect,
  DEFAULT_CONTAINER,
} from 'modules/tiptap_editor/styles/containerStyles'
import { useUserContext } from 'modules/user'
import { generateFontsUrl } from 'utils/url'

import { THEME_BASES } from '../themeBases'
import { FontMap, FontPickerFonts, Theme } from '../types'
import { getFontWeightOptions, getUpdatedThemeFonts } from '../utils/fonts'
import { getThemeBackgroundOrDefault, isThemeDark } from '../utils/utils'
import { ColorOrGradientPicker } from './ColorOrGradientPicker'
import { ColorPalettePicker } from './ColorPalettePicker'
import { FontPicker } from './FontPicker'
import { FontWeightPicker } from './FontWeightPicker'
import { LinearGradient } from './LinearGradientPicker'
import { LogoPicker } from './LogoPicker'
import { TextColorFormControl } from './TextColorFormControl'

const accordionPanelStyles = {
  p: 6,
}

const adminOnlyAccordionStyles = {
  outline: '3px dashed var(--chakra-colors-sunglow-500)',
}
const adminOnlyFormControlStyles = {
  outline: '3px dashed var(--chakra-colors-sunglow-500)',
  outlineOffset: 4,
}

export const ThemeAccordionIcon = () => {
  return (
    <Box
      className="accordion-icon"
      transitionProperty="common"
      transitionDuration="normal"
      color="gray.800"
    >
      <FontAwesomeIcon size="xs" fixedWidth icon={regular('chevron-down')} />
    </Box>
  )
}

export type ThemeAccordionButtonProps = {
  icon: IconDefinition
  label: string
} & AccordionButtonProps

export const ThemeAccordionButton = ({
  icon,
  label,
  ...buttonProps
}: ThemeAccordionButtonProps) => {
  return (
    <AccordionButton
      sx={{
        _expanded: { '.accordion-icon': { transform: 'rotate(-180deg)' } },
      }}
      {...buttonProps}
    >
      <ThemeAccordionHeader label={label} icon={icon} />
      <ThemeAccordionIcon />
    </AccordionButton>
  )
}

export const ThemeAccordionHeader = ({ icon, label }) => {
  return (
    <HStack flex="1" textAlign="left" py="1.5" px="0">
      <Box color="trueblue.600">
        <FontAwesomeIcon fixedWidth icon={icon} />
      </Box>
      <Text fontWeight="600" fontSize="md">
        {label}
      </Text>
    </HStack>
  )
}

type ThemeConfigPanelProps = {
  theme: Theme
  updateTheme: (theme: Partial<Theme>) => void
  updateThemeConfig: (config: Partial<Theme['config']>) => void
  fonts: FontPickerFonts
  fontsMap: FontMap
  themeValidationError: string | null
  setThemeValidationError: Dispatch<SetStateAction<string | null>>
}

export const ThemeConfigPanel = ({
  theme,
  updateTheme,
  updateThemeConfig,
  fonts,
  fontsMap,
  themeValidationError,
  setThemeValidationError,
}: ThemeConfigPanelProps) => {
  const updateAccentColor = useCallback(
    (color: string | null) => updateTheme({ accentColor: color ?? undefined }),
    [updateTheme]
  )
  const updateHeadingColor = useCallback(
    (color: string | null) =>
      updateThemeConfig({ headingColor: color ?? undefined }),
    [updateThemeConfig]
  )
  const updateHeadingGradient = useCallback(
    (gradient: LinearGradient | null) =>
      updateThemeConfig({ headingGradient: gradient ?? undefined }),
    [updateThemeConfig]
  )
  const updateBodyColor = useCallback(
    (color: string | null) =>
      updateThemeConfig({ bodyColor: color ?? undefined }),
    [updateThemeConfig]
  )

  const updateHeadingFontAndWeight = useCallback(
    (fontId: string) => {
      const headingFont = fontsMap[fontId]
      const fontWeights = getFontWeightOptions(headingFont)
      const nextFontWeight = findClosestFontWeight(
        fontWeights,
        theme.headingFontWeight || DEFAULT_FONT_WEIGHTS.heading
      )
      const nextThemeFonts = getUpdatedThemeFonts({
        fontsMap,
        headingFontId: fontId,
        bodyFontId: theme.bodyFont,
      })
      updateTheme({
        headingFont: fontId,
        headingFontWeight: nextFontWeight,
        fonts: nextThemeFonts,
      })
    },
    [fontsMap, updateTheme, theme.bodyFont, theme.headingFontWeight]
  )

  const updateBodyFontAndWeight = useCallback(
    (fontId: string) => {
      const bodyFont = fontsMap[fontId]
      const fontWeights = getFontWeightOptions(bodyFont)
      const nextFontWeight = findClosestFontWeight(
        fontWeights,
        theme.bodyFontWeight || DEFAULT_FONT_WEIGHTS.body
      )
      const nextThemeFonts = getUpdatedThemeFonts({
        fontsMap,
        bodyFontId: fontId,
        headingFontId: theme.headingFont,
      })
      updateTheme({
        bodyFont: fontId,
        bodyFontWeight: nextFontWeight,
        fonts: nextThemeFonts,
      })
    },
    [fontsMap, updateTheme, theme.headingFont, theme.bodyFontWeight]
  )

  const handleFontUploadClick = useCallback(() => {
    const fontUrl = generateFontsUrl()
    window.open(fontUrl, '_blank')
  }, [])

  const isDark = isThemeDark(theme)
  const background = getThemeBackgroundOrDefault(theme)
  const { isGammaOrgUser } = useUserContext()
  const v2enabled = useFeatureFlag('themes2')
  const customFontsEnabled = useFeatureFlag('customFonts')

  const headingFont =
    fontsMap[theme.headingFont || DEFAULT_FONTS.headingFont] ||
    fontsMap[DEFAULT_FONTS.headingFont]
  const bodyFont =
    fontsMap[theme.bodyFont || DEFAULT_FONTS.bodyFont] ||
    fontsMap[DEFAULT_FONTS.bodyFont]
  const headingWeight = theme.headingFontWeight || DEFAULT_FONT_WEIGHTS.heading
  const bodyWeight = theme.bodyFontWeight || DEFAULT_FONT_WEIGHTS.body

  const cardBackgroundColor =
    theme.config.cardBackground?.type === BackgroundType.COLOR
      ? theme.config.cardBackground.color
      : null

  return (
    <Stack spacing={4}>
      <FormControl
        isInvalid={Boolean(themeValidationError)}
        px={4}
        pt={4}
        pb={2}
      >
        <FormLabel>Theme name</FormLabel>
        <Input
          type="text"
          placeholder="Untitled theme"
          value={theme.name}
          onChange={(e) => {
            setThemeValidationError(null)
            updateTheme({ name: e.target.value })
          }}
          data-form-type="other" // Prevents password managers treating this as a name
          data-testid="custom-theme-name-input"
        />
        {themeValidationError && (
          <FormErrorMessage>{themeValidationError}</FormErrorMessage>
        )}
      </FormControl>
      <Accordion allowToggle>
        {/* FONTS */}
        <AccordionItem>
          <ThemeAccordionButton
            label="Fonts and colors"
            icon={duotone('font')}
            data-testid="custom-theme-fonts"
          />
          <AccordionPanel {...accordionPanelStyles}>
            <Stack>
              <FormControl mb="2">
                <FormLabel>
                  <Text>Heading font</Text>
                </FormLabel>
                <FontPicker
                  fonts={fonts}
                  value={theme.headingFont}
                  customFontsEnabled={customFontsEnabled}
                  updateValue={updateHeadingFontAndWeight}
                  onFontUploadClick={handleFontUploadClick}
                  weight="bold"
                  defaultFont={DEFAULT_FONTS.headingFont}
                  data-testid="custom-theme-heading-font-picker"
                />
                {customFontsEnabled && (
                  <FontWeightPicker
                    font={headingFont}
                    value={headingWeight}
                    defaultWeight={700}
                    updateValue={(weight) =>
                      updateTheme({ headingFontWeight: weight })
                    }
                  />
                )}
              </FormControl>
              {/* HEADING COLOR */}
              <FormControl
                mb="2"
                data-testid="custom-theme-heading-color-picker"
              >
                <FormLabel>
                  <Text>Heading color</Text>
                </FormLabel>
                <ColorOrGradientPicker
                  color={theme.config.headingColor}
                  updateColor={updateHeadingColor}
                  defaultColor={
                    isDark ? DEFAULT_HEADING_COLOR_DARK : DEFAULT_HEADING_COLOR
                  }
                  gradient={theme.config.headingGradient}
                  updateGradient={updateHeadingGradient}
                />
              </FormControl>
              {/* BODY FONT */}
              <FormControl>
                <FormLabel>
                  <Text>Body font</Text>
                </FormLabel>
                <FontPicker
                  fonts={fonts}
                  value={theme.bodyFont}
                  customFontsEnabled={customFontsEnabled}
                  updateValue={updateBodyFontAndWeight}
                  onFontUploadClick={handleFontUploadClick}
                  weight="normal"
                  defaultFont={DEFAULT_FONTS.bodyFont}
                  data-testid="custom-theme-body-font-picker"
                />
                {customFontsEnabled && (
                  <FontWeightPicker
                    font={bodyFont}
                    value={bodyWeight}
                    defaultWeight={400}
                    updateValue={(weight) =>
                      updateTheme({ bodyFontWeight: weight })
                    }
                  />
                )}
              </FormControl>
              {/* BODY COLOR */}
              <TextColorFormControl
                label="Body color"
                value={theme.config?.bodyColor || null}
                defaultValue={
                  isDark ? DEFAULT_BODY_COLOR_DARK : DEFAULT_BODY_COLOR
                }
                updateValue={updateBodyColor}
              />
              <Alert fontSize="xs" mt="6" colorScheme="gray">
                <AlertIcon />
                <Text>
                  Note: these colors may be lightened or darkened for contrast
                  and accessibility.
                </Text>
              </Alert>
            </Stack>
          </AccordionPanel>
        </AccordionItem>

        {/* COLORS */}
        <AccordionItem>
          <ThemeAccordionButton
            label="Accent colors"
            icon={duotone('droplet')}
            data-testid="custom-theme-colors"
          />
          <AccordionPanel {...accordionPanelStyles}>
            <Stack>
              <FormControl data-testid="custom-theme-accent-color-picker">
                <FormLabel>Primary accent color</FormLabel>
                <FormHelperText mb={4}>
                  You can set an accent color or gradient that will be applied
                  to elements like links, buttons, and blockquotes.
                </FormHelperText>
                <ColorOrGradientPicker
                  color={theme.accentColor}
                  defaultColor={DEFAULT_ACCENT_COLOR}
                  updateColor={updateAccentColor}
                  gradient={theme.config.accentGradient}
                  updateGradient={(accentGradient) =>
                    updateThemeConfig({ accentGradient })
                  }
                />
              </FormControl>
              {v2enabled && (
                <FormControl {...adminOnlyFormControlStyles}>
                  <FormLabel>Secondary accent colors</FormLabel>
                  <FormHelperText mb={2}>
                    Additional accent colors will be applied to decorative
                    elements in your theme such as table dividers and timelines.
                    If you don’t add any secondary accent colors, the primary
                    accent color or gradient will be applied.
                  </FormHelperText>
                  <ColorPalettePicker
                    value={theme.config?.secondaryAccentColors || []}
                    updateValue={(secondaryAccentColors) =>
                      updateThemeConfig({ secondaryAccentColors })
                    }
                    maxColors={4}
                  />
                </FormControl>
              )}
            </Stack>
            <Alert fontSize="xs" mt="6" colorScheme="gray">
              <AlertIcon />
              <Text>
                Note: these colors may be lightened or darkened for contrast and
                accessibility.
              </Text>
            </Alert>
          </AccordionPanel>
        </AccordionItem>

        {/* CARD STYLES */}
        {v2enabled ? (
          <AccordionItem>
            <ThemeAccordionButton
              label="Card colors"
              icon={duotone('rectangle-history')}
              data-testid="custom-theme-card-style"
            />
            <AccordionPanel>
              <Stack>
                <FormControl data-testid="custom-theme-card-color-picker">
                  <FormLabel>Card color</FormLabel>
                  <FormHelperText mb={4}>
                    The default background color of the card itself. Text colors
                    will adjust automatically to stand out against this
                    background.
                  </FormHelperText>
                  {/* Right now, we only allow colors for the card background. In the future we may allow other types, so we'll model this as BackgroundOptions but only set color ones for now. */}
                  <ColorPickerInput
                    value={cardBackgroundColor?.hex || null}
                    updateValue={(color) =>
                      updateThemeConfig({
                        cardBackground: color
                          ? {
                              type: BackgroundType.COLOR,
                              color: {
                                hex: color,
                              },
                            }
                          : undefined,
                      })
                    }
                    defaultValue="#FFFFFF" // todo: inherit from base theme
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Theme base</FormLabel>
                  <Select
                    value={theme.config.themeBase || 'default'}
                    onChange={(e) => {
                      updateThemeConfig({ themeBase: e.target.value })
                    }}
                  >
                    {THEME_BASES.map((base) => {
                      return (
                        <option key={base.key} value={base.key}>
                          {base.key}
                        </option>
                      )
                    })}
                  </Select>
                </FormControl>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        ) : (
          <AccordionItem>
            <ThemeAccordionButton
              label="Card style"
              icon={duotone('rectangle-history')}
              data-testid="custom-theme-card-style"
            />
            <AccordionPanel {...accordionPanelStyles}>
              <ContainerStylePanel
                container={theme.config?.container || DEFAULT_CONTAINER}
                background={background || DEFAULT_THEME_BACKGROUND}
                docBackground={DEFAULT_DOC_BACKGROUND}
                enableBackgroundSizes={false}
                theme={theme}
                updateAttributes={updateThemeConfig}
                disableEffects={isGammaOrgUser ? [] : [ContainerEffect.CLEAR]}
              />
            </AccordionPanel>
          </AccordionItem>
        )}

        {/* BACKGROUND */}
        <AccordionItem>
          <ThemeAccordionButton
            label="Background"
            icon={duotone('swatchbook')}
            data-testid="custom-theme-background"
          />
          <AccordionPanel {...accordionPanelStyles}>
            <BackgroundPanel
              updateAttributes={updateThemeConfig}
              background={background}
              defaultMessage={
                <Alert>
                  <AlertIcon />
                  Choose an option above to set a default background for all{' '}
                  {DOC_DISPLAY_NAME_PLURAL} using this theme.
                </Alert>
              }
              isDark={isThemeDark(theme)}
            />
          </AccordionPanel>
        </AccordionItem>

        {/* LOGO */}
        <AccordionItem>
          <ThemeAccordionButton
            label="Logo"
            icon={theme.logoUrl ? duotone('cat-space') : duotone('cat')}
            data-testid="custom-theme-logo"
          />
          <AccordionPanel {...accordionPanelStyles}>
            <FormControl>
              <LogoPicker
                value={theme.logoUrl || null}
                updateValue={(src) =>
                  updateTheme({ logoUrl: src } as Partial<Theme>)
                }
              />
            </FormControl>
          </AccordionPanel>
        </AccordionItem>

        {/* GAMMA USERS */}
        {isGammaOrgUser && (
          <AccordionItem {...adminOnlyAccordionStyles}>
            <ThemeAccordionButton label="Advanced" icon={duotone('gear')} />
            <AccordionPanel {...accordionPanelStyles}>
              <Stack>
                <CSSinJSFormControl
                  label="Content CSS"
                  initialValue={theme.config?.contentStyles}
                  updateValue={(json) =>
                    updateThemeConfig({ contentStyles: json })
                  }
                />
                <CSSinJSFormControl
                  label="Card effects"
                  initialValue={theme.config?.containerStyles}
                  updateValue={(json) =>
                    updateThemeConfig({ containerStyles: json })
                  }
                  helperText="Applies to expanded cards."
                />
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        )}
      </Accordion>
    </Stack>
  )
}
