import { featureFlags } from 'modules/featureFlags'

// https://gamma.app/docs/Moonshot--ewrpdsp1fsi252v
export const EXAMPLE_DOC = {
  type: 'doc',
  content: [
    {
      type: 'document',
      attrs: {
        docId: 'ewrpdsp1fsi252v',
        theme: 'default-light',
        background: {
          type: 'none',
        },
        docFlags: {
          cardLayoutsEnabled: featureFlags.get('cardLayoutsEnabled'),
        },
      },
      content: [
        {
          type: 'card',
          attrs: {
            id: '79x0aptp30db4x6',
            background: {
              type: 'none',
            },
            container: {},
          },
          content: [
            {
              type: 'gridLayout',
              content: [
                {
                  type: 'gridCell',
                  content: [
                    {
                      type: 'title',
                      attrs: {
                        horizontalAlign: null,
                        level: 1,
                      },
                      content: [
                        {
                          type: 'text',
                          text: 'Moonshot ',
                        },
                        {
                          type: 'emoji',
                          attrs: {
                            id: 'rocket',
                            native: '🚀',
                          },
                        },
                        {
                          type: 'text',
                          text: ' ',
                        },
                      ],
                    },
                    {
                      type: 'paragraph',
                      attrs: {
                        horizontalAlign: null,
                      },
                      content: [
                        {
                          type: 'text',
                          text: 'The inside story of the race to the moon.',
                        },
                      ],
                    },
                    {
                      type: 'blockquote',
                      content: [
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: "That's one small step for a man. One giant leap for mankind.",
                            },
                            {
                              type: 'hardBreak',
                            },
                            {
                              type: 'text',
                              text: '- Neil Armstrong',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'gridCell',
                  content: [
                    {
                      type: 'image',
                      attrs: {
                        id: 'BRPK1',
                        horizontalAlign: null,
                        src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/6e2e819571b34e2db79c1a37343961c2/optimized/image.png',
                        tempUrl: null,
                        uploadStatus: 0,
                        meta: {
                          width: 938,
                          height: 864,
                          date_file_modified: '2022/06/10 14:06:09 GMT',
                          aspect_ratio: 1.086,
                          has_clipping_path: false,
                          frame_count: 1,
                          colorspace: 'RGB',
                          average_color: '#6a676b',
                        },
                        name: 'image.png',
                        query: null,
                        source: 'image.custom',
                        showPlaceholder: false,
                        fullWidthBlock: false,
                        resize: {
                          clipType: null,
                          clipPath: null,
                          clipAspectRatio: null,
                          width: null,
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: '03qtimgf1a7rt8w',
            background: {
              type: 'none',
            },
            container: {},
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 1,
              },
              content: [
                {
                  type: 'text',
                  text: 'Apollo Mission Goals',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'The national effort that enabled Astronaut Neil Armstrong to speak those words as he stepped onto the lunar surface fulfilled a dream as old as humanity. ',
                },
                {
                  type: 'text',
                  marks: [
                    {
                      type: 'link',
                      attrs: {
                        href: 'https://www.nasa.gov/mission_pages/apollo/missions/index.html',
                      },
                    },
                  ],
                  text: "Project Apollo's goals",
                },
                {
                  type: 'text',
                  text: ' went beyond landing Americans on the moon and returning them safely to Earth. They included:',
                },
              ],
            },
            {
              type: 'todo',
              attrs: {
                indent: 0,
                checked: true,
              },
              content: [
                {
                  type: 'text',
                  text: 'Carrying out a program of scientific exploration of the Moon.',
                },
              ],
            },
            {
              type: 'todo',
              attrs: {
                indent: 0,
                checked: false,
              },
              content: [
                {
                  type: 'text',
                  text: 'Achieving preeminence in space for the United States.',
                },
              ],
            },
            {
              type: 'todo',
              attrs: {
                indent: 0,
                checked: false,
              },
              content: [
                {
                  type: 'text',
                  text: 'Developing human capability to work in the lunar environment.',
                },
              ],
            },
            {
              type: 'todo',
              attrs: {
                indent: 0,
                checked: false,
              },
              content: [
                {
                  type: 'text',
                  text: 'Establishing the technology to meet other national interests in space.',
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: '7ks4hcfqoltidfy',
            background: {
              type: 'none',
            },
            container: {},
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 1,
              },
              content: [
                {
                  type: 'text',
                  text: 'The Saturn V Rocket',
                },
              ],
            },
            {
              type: 'gridLayout',
              content: [
                {
                  type: 'gridCell',
                  content: [
                    {
                      type: 'paragraph',
                      attrs: {
                        horizontalAlign: null,
                      },
                      content: [
                        {
                          type: 'text',
                          text: "The Saturn V's ",
                        },
                        {
                          type: 'text',
                          marks: [
                            {
                              type: 'footnoteLabel',
                              attrs: {
                                noteId: 't15Fj',
                              },
                            },
                          ],
                          text: 'size and payload capacity',
                        },
                        {
                          type: 'footnote',
                          attrs: {
                            noteId: 't15Fj',
                          },
                          content: [
                            {
                              type: 'paragraph',
                              attrs: {
                                horizontalAlign: null,
                              },
                              content: [
                                {
                                  type: 'text',
                                  text: 'With the Apollo spacecraft on top, it stood 363 feet tall, and, ignoring the fins, was 33 feet in diameter. Fully fueled, the Saturn V weighed 6.5 million pounds.',
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'text',
                          text: ' dwarfed all other previous rockets successfully flown at that time.',
                        },
                      ],
                    },
                    {
                      type: 'heading',
                      attrs: {
                        horizontalAlign: null,
                        level: 3,
                      },
                      content: [
                        {
                          type: 'text',
                          text: 'Fun facts',
                        },
                      ],
                    },
                    {
                      type: 'numbered',
                      attrs: {
                        indent: 0,
                      },
                      content: [
                        {
                          type: 'text',
                          text: 'The Saturn V is the ',
                        },
                        {
                          type: 'text',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          text: 'tallest rocket ever built',
                        },
                        {
                          type: 'text',
                          text: ', standing at a whopping ',
                        },
                        {
                          type: 'text',
                          marks: [
                            {
                              type: 'footnoteLabel',
                              attrs: {
                                noteId: 'fMT3y',
                              },
                            },
                          ],
                          text: '363-feet-tall',
                        },
                        {
                          type: 'footnote',
                          attrs: {
                            noteId: 'fMT3y',
                          },
                          content: [
                            {
                              type: 'paragraph',
                              attrs: {
                                horizontalAlign: null,
                              },
                              content: [
                                {
                                  type: 'text',
                                  text: "That's about the height of a 36-story-tall building. The Saturn V Rocket is about 60 feet taller than the Statue of Liberty.",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'text',
                          text: '.',
                        },
                      ],
                    },
                    {
                      type: 'numbered',
                      attrs: {
                        indent: 0,
                      },
                      content: [
                        {
                          type: 'text',
                          text: 'It burned ',
                        },
                        {
                          type: 'text',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          text: 'more fuel',
                        },
                        {
                          type: 'text',
                          text: ' in 1 second than Lindbergh used to cross the Atlantic.',
                        },
                      ],
                    },
                    {
                      type: 'numbered',
                      attrs: {
                        indent: 0,
                      },
                      content: [
                        {
                          type: 'text',
                          text: 'It went from paper design to flight in ',
                        },
                        {
                          type: 'text',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          text: '6 years',
                        },
                        {
                          type: 'text',
                          text: '.',
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'gridCell',
                  content: [
                    {
                      type: 'video',
                      attrs: {
                        id: 'idUDM',
                        embedUrl: null,
                        source: 'video.vimeo',
                        thumbnail: {
                          src: 'https://i.vimeocdn.com/video/210584124-ca00b17a546f449fa6c6fed25fab8f2a550d2b71924f0cbaaf8b5521cc73e78b-d_480',
                          height: 270,
                          width: 480,
                        },
                        embed: {
                          url: 'https://player.vimeo.com/video/31266267?h=3212b4d206&app_id=122963&byline=0&badge=0&portrait=0&title=0',
                          html: '<div><div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.25%;"><iframe src="//cdn.iframe.ly/api/iframe?url=https%3A%2F%2Fvimeo.com%2F31266267%3Fembedded%3Dtrue%26source%3Dvideo_title%26owner%3D1634425&key=a68bac8b6624d46b6d0ba46e5b3f8971" style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;" allowfullscreen scrolling="no" allow="encrypted-media *;"></iframe></div></div>',
                        },
                        meta: {
                          title: 'Saturn V - Apollo 13 (AS-508) Camera E72',
                          author: 'Pizza Marko',
                          author_url: 'https://vimeo.com/user1634425',
                          duration: 144,
                          site: 'Vimeo',
                          description:
                            "I've always loved the colors in this particular Saturn V liftoff.\n\nCheck out our DVD selections of rare material on space exploration at www.spacecraftfilms.com.",
                          canonical: 'https://vimeo.com/31266267',
                          date: '2011-10-28',
                          medium: 'video',
                          icon: 'https://i.vimeocdn.com/favicon/main-touch_180',
                        },
                        sourceUrl: 'https://vimeo.com/31266267',
                        displayStyle: 'inline',
                      },
                    },
                    {
                      type: 'video',
                      attrs: {
                        id: 'yvbIA',
                        embedUrl: null,
                        source: 'video.youtube',
                        thumbnail: {
                          src: 'https://i.ytimg.com/vi/EbmqSZ6f8ks/maxresdefault.jpg',
                          height: 720,
                          width: 1280,
                        },
                        embed: {
                          url: 'https://www.youtube.com/embed/EbmqSZ6f8ks?rel=0',
                          html: '<div><div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.25%;"><iframe src="//cdn.iframe.ly/api/iframe?url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DEbmqSZ6f8ks&key=a68bac8b6624d46b6d0ba46e5b3f8971" style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;" allowfullscreen scrolling="no" allow="accelerometer *; clipboard-write *; encrypted-media *; gyroscope *; picture-in-picture *;"></iframe></div></div>',
                        },
                        meta: {
                          title:
                            'Inside NASA: The Saturn V Rocket Story | Space Race Documentary | Timeline',
                          date: '2019-07-25T17:00:00.000Z',
                          author: 'Timeline - World History Documentaries',
                          description:
                            "In 1961 when President Kennedy pledged to put a man on the moon by the end of the decade, no rocket existed with the power or capability to rise to the challenge. In order to win the race to space, the United States would need to establish a multi-billion dollar space program.\n\nOne man, Werner Von Braun believed he had the knowledge and vision to make Kennedy's dream a reality.\n\nWith the American public galvanised and the expertise of over 200,000 scientists and engineers, Von Braun masterminded the development of the Saturn V; the rocket that flew 24 men to the moon and launched the greatest adventure in the history of exploration.\n\nThis is the story of the most powerful machine ever built, and the men and women who believed it could fly.\n\nUsing visual effects, stunning NASA footage and expert interviews with Apollo Space Scientists, this inspirational film tells the story of the colossal challenges NASA faced to fulfill Kennedy's pledge.\n\nWith the accolade of flying 24 men safely to the moon, the mighty Saturn V will always be considered one of mankind's greatest technological achievements.\n\n It's like Netflix for history... Sign up to History Hit, the world's best history documentary service, at a huge discount using the code 'TIMELINE' ---ᐳ http://bit.ly/3a7ambu\n\nYou can find more from us on:\n\nhttps://www.facebook.com/timelineWH\n\nhttps://www.instagram.com/timelineWH\n\nThis channel is part of the History Hit Network. Any queries, please contact owned-enquiries@littledotstudios.com",
                          duration: 3130,
                          likes: '6484',
                          views: '630604',
                          site: 'YouTube',
                          canonical:
                            'https://www.youtube.com/watch?v=EbmqSZ6f8ks',
                          author_url:
                            'https://www.youtube.com/channel/UC88lvyJe7aHZmcvzvubDFRg',
                          medium: 'video',
                          icon: 'https://www.youtube.com/s/desktop/d4eb50c8/img/favicon_144x144.png',
                        },
                        sourceUrl:
                          'https://www.youtube.com/watch?v=EbmqSZ6f8ks',
                        displayStyle: 'inline',
                      },
                    },
                  ],
                },
              ],
            },
            {
              type: 'card',
              attrs: {
                id: '282um0rffywak15',
                background: {
                  type: 'none',
                },
                container: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'The Route to the Moon',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'Apollo 11\'s flight path took the spacecraft into Earth orbit 11 minutes after launch. Just over two hours later, during its second orbit, the rocket\'s third stage fired again to boost Apollo towards the moon - the so-called Trans Lunar Insertion or TLI. The TLI placed Apollo on a "free-return trajectory" - often illustrated as a figure of eight shape.',
                    },
                    {
                      type: 'hardBreak',
                    },
                    {
                      type: 'hardBreak',
                    },
                    {
                      type: 'text',
                      text: 'This course would have harnessed the power of the Moon\'s gravity to propel the spacecraft back to Earth without the need for more rocket fuel. However, when Apollo 11 neared its destination, astronauts performed a braking manoeuvre known as "lunar orbit insertion" to slow the spacecraft and cause it to go into orbit around the Moon.',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}
