import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  SkeletonText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  docTitleOrPlaceholder,
  DOC_DISPLAY_NAME,
  DOC_DISPLAY_NAME_PLURAL,
} from '@gamma-app/ui'
import { JSONContent } from '@tiptap/core'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import {
  Doc,
  ExistingWorkspace,
  GetDocQuery,
  useGetThemeQuery,
} from 'modules/api'
import { selectCards } from 'modules/cards/reducer'
import { useAppSelector } from 'modules/redux'
import { EditorStatic } from 'modules/tiptap_editor'
import { GlobalCardStyles } from 'modules/tiptap_editor/extensions/Card/GlobalCardStyles'
import { DEFAULT_DOC_BACKGROUND } from 'modules/tiptap_editor/styles/backgroundStyles'
import { useDebounced } from 'utils/hooks'

import { EXAMPLE_DOC } from '../exampleDoc'
import { useGetFonts } from '../hooks'
import { Theme } from '../types'
import { FontLoader } from './FontLoader'
import { ThemeConfigPanel } from './ThemeConfigPanel'

const THEME_VIEWER_CLASS = 'theme-editor-root'

interface ThemeEditorProps {
  theme: Theme
  updateTheme: (theme: Partial<Theme>) => void
  updateThemeConfig: (config: Partial<Theme['config']>) => void
  docContent?: JSONContent
  doc?: Doc
  workspaceId?: ExistingWorkspace['id']
  themeValidationError: string | null
  setThemeValidationError: Dispatch<SetStateAction<string | null>>
  onCancel: () => void
  onSave: () => void
  isLoading: boolean
}

export const ThemeEditor = ({
  theme,
  updateTheme,
  updateThemeConfig,
  docContent,
  doc,
  workspaceId,
  themeValidationError,
  setThemeValidationError,
  onCancel,
  onSave,
  isLoading,
}: ThemeEditorProps) => {
  // A live preview of the state. Debounced to avoid constant updates while typing
  const [themePreview, setThemePreview] = useState<Theme>()
  const setThemePreviewDebounced = useDebounced(setThemePreview, 300)
  useEffect(() => {
    setThemePreviewDebounced(theme)
  }, [theme, setThemePreviewDebounced])

  const { globalFonts, workspaceFonts, fontsMap, themeFonts } =
    useGetFonts(workspaceId)

  // Force the doc to use the theme's background and not its own bg,
  // so that you can see what you're changing
  if (docContent?.content && docContent.content[0].attrs?.background) {
    docContent.content[0].attrs.background = DEFAULT_DOC_BACKGROUND
  }
  const cardData = useAppSelector(selectCards)
  const configPanelWidth =
    useBreakpointValue({
      base: 'var(--chakra-sizes-xs)',
      lg: 'var(--chakra-sizes-md)',
      '2xl': 'var(--chakra-sizes-xl)',
    }) || 'var(--chakra-sizes-md)'

  return (
    <Flex direction="row" h="100%">
      {/* Main doc viewer */}
      <Flex
        flex={1}
        bg="gray.100"
        position="relative"
        maxW={`calc(100vw - ${configPanelWidth})`}
      >
        <Flex
          className={THEME_VIEWER_CLASS}
          overflow="scroll"
          width="100%"
          height="100%"
          direction="column"
          position="relative"
        >
          <GlobalCardStyles />
          <FontLoader fonts={themeFonts} />
          <Tabs variant="soft-rounded" size="sm">
            {docContent && (
              // Only show the tabs if both of them will show
              <Center position="sticky" top={0} zIndex={2} background="white">
                <TabList
                  p={2}
                  position="absolute"
                  top="1rem"
                  background="white"
                  borderRadius="full"
                  shadow="md"
                >
                  <Tab>
                    <HStack>
                      <FontAwesomeIcon icon={regular('rectangle-history')} />
                      <Text>Current {DOC_DISPLAY_NAME}</Text>
                    </HStack>
                  </Tab>

                  <Tab>
                    <HStack>
                      <FontAwesomeIcon icon={solid('eye')} />
                      <Text>Tester {DOC_DISPLAY_NAME}</Text>
                    </HStack>
                  </Tab>
                </TabList>
              </Center>
            )}
            <TabPanels>
              {docContent && (
                <TabPanel p={0} data-testid="custom-theme-preview-editor">
                  <EditorStatic
                    reduxData={{ cards: cardData }}
                    initialContent={docContent}
                    theme={themePreview}
                    scrollingParentSelector={`.${THEME_VIEWER_CLASS}`}
                    doc={doc}
                  />
                </TabPanel>
              )}
              <TabPanel p={0} data-testid="custom-theme-preview-editor">
                <EditorStatic
                  initialContent={EXAMPLE_DOC}
                  theme={themePreview}
                  scrollingParentSelector={`.${THEME_VIEWER_CLASS}`}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
      {/* Sidebar with theme config */}
      <Flex
        direction="column"
        w={configPanelWidth}
        borderLeft="1px solid"
        borderColor="gray.200"
      >
        <Flex overflowY="scroll" overflowX="hidden" flex="1" direction="column">
          <ThemeConfigPanel
            theme={theme}
            updateTheme={updateTheme}
            updateThemeConfig={updateThemeConfig}
            fonts={{ globalFonts, workspaceFonts }}
            fontsMap={fontsMap}
            themeValidationError={themeValidationError}
            setThemeValidationError={setThemeValidationError}
          />
        </Flex>
        <ButtonGroup
          spacing={4}
          borderTop="1px solid #000"
          borderColor="gray.200"
          p={4}
          justifyContent="flex-end"
        >
          <Button variant="ghost" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            variant="solid"
            onClick={onSave}
            data-testid="custom-theme-save"
          >
            Save
          </Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  )
}

interface ThemeEditorHeaderProps {
  name?: string
  doc?: GetDocQuery['doc']
  theme?: Theme
}

export const ThemeEditorHeader = ({
  name,
  doc,
  theme,
}: ThemeEditorHeaderProps) => {
  const [fetchedTheme, setFetchedTheme] = useState<Theme>()

  // Refetch here because theme.docCount is expensive to calculate so we only
  // get it when you edit a theme
  const { data, loading } = useGetThemeQuery({
    variables: {
      id: theme?.id,
    },
    skip: !theme || theme.id === 'new',
  })
  const docTitle = docTitleOrPlaceholder(doc?.title)

  useEffect(() => {
    if (loading || !data) return
    if (data.theme) {
      setFetchedTheme(data.theme)
    }
  }, [loading, data, setFetchedTheme])

  return (
    <>
      <Text mt={1} noOfLines={1}>
        {name || 'Untitled theme'}
      </Text>
      {loading ? (
        <SkeletonText noOfLines={1} fontSize="sm" maxW={'300px'} />
      ) : (
        <Text
          color="gray.700"
          fontSize="sm"
          fontWeight="normal"
          letterSpacing="normal"
        >
          {fetchedTheme?.createdBy && (
            <>Created by {fetchedTheme.createdBy.displayName}. </>
          )}
          {fetchedTheme?.docCount &&
          fetchedTheme?.docCount === 1 &&
          fetchedTheme?.id === doc?.theme?.id ? (
            <>
              <strong>{docTitle}</strong> is the only {DOC_DISPLAY_NAME} using
              this theme.
            </>
          ) : (
            <>
              <strong>
                {fetchedTheme?.docCount || 0}{' '}
                {fetchedTheme?.docCount === 1
                  ? DOC_DISPLAY_NAME
                  : DOC_DISPLAY_NAME_PLURAL}
              </strong>{' '}
              {fetchedTheme?.docCount === 1 ? 'is' : 'are'} using this theme.
            </>
          )}
        </Text>
      )}
    </>
  )
}
