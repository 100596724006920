import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Button,
  Link,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useMemo } from 'react'

import { Font } from 'modules/api'
import { WEIGHTS_MAP } from 'modules/fonts/constants'
import { generateFontsUrl } from 'utils/url'

import { getFontWeightOptions } from '../utils/fonts'
import { FontPickerWarningLabel } from './FontPickerWarningLabel'

type FontWeightPickerProps = {
  font?: Font
  value?: number
  defaultWeight: number
  updateValue: (val: number) => void
} & MenuButtonProps

export const FontWeightPicker = ({
  font,
  value,
  defaultWeight,
  updateValue,
  ...buttonProps
}: FontWeightPickerProps) => {
  const onChange = useCallback(
    (val: string) => {
      const newWeight = Number(val)
      if (!Number.isNaN(newWeight)) {
        updateValue(newWeight)
      }
    },
    [updateValue]
  )

  const currentWeight = value?.toString() || ''
  const defaultWeightName: string = WEIGHTS_MAP[defaultWeight]

  const { weightOptions, isDeletedFontWeight } = useMemo(() => {
    const weights = getFontWeightOptions(font)
    const isDeletedWeight =
      typeof value !== 'undefined' && !weights.includes(value)
    if (!isDeletedWeight || !value) {
      return { isDeletedFontWeight: isDeletedWeight, weightOptions: weights }
    }
    // make sure the deleted value is added back in and sorted
    const allWeights = [...weights, value].sort((a, b) => a - b)
    return { isDeletedFontWeight: isDeletedWeight, weightOptions: allWeights }
  }, [font, value])
  const isCustomFont = font && font?.workspaceId !== null
  const isEmptyCustomFont = isCustomFont && font.fontFiles?.length === 0
  const isDeletedCustomFontWeight = isDeletedFontWeight && isCustomFont

  const fontUrl = generateFontsUrl()

  return (
    <>
      <Menu matchWidth isLazy strategy="fixed">
        <MenuButton
          size="lg"
          as={Button}
          borderRadius="md"
          rightIcon={
            <FontAwesomeIcon
              icon={regular('chevron-down')}
              transform="shrink-6"
            />
          }
          variant="toolbar"
          px={4}
          h={10}
          w="100%"
          border="1px solid"
          borderColor="gray.200"
          disabled={weightOptions.length <= 1}
          {...buttonProps}
        >
          {isDeletedFontWeight ? (
            <FontPickerWarningLabel
              label={
                isEmptyCustomFont
                  ? 'Empty font family'
                  : WEIGHTS_MAP[currentWeight] ?? defaultWeightName
              }
              labelColor={value ? 'gray.800' : 'gray.400'}
            />
          ) : (
            <Text
              textAlign="left"
              color={value ? 'gray.800' : 'gray.400'}
              fontWeight={400}
            >
              {WEIGHTS_MAP[currentWeight] ?? defaultWeightName}
            </Text>
          )}
        </MenuButton>
        <MenuList maxH="320px" overflow="auto" zIndex="dropdown">
          <MenuOptionGroup
            type="radio"
            value={currentWeight}
            onChange={onChange}
          >
            {weightOptions.map((weight) => {
              if (isDeletedFontWeight && value === weight) {
                return (
                  <MenuItemOption
                    key={weight}
                    value={weight.toString()}
                    disabled
                  >
                    <FontPickerWarningLabel
                      label={WEIGHTS_MAP[weight] ?? defaultWeightName}
                      description={
                        isDeletedCustomFontWeight
                          ? `This weight no longer exists in this font family. You can upload font files for it, or choose a different one.`
                          : `This weight isn't available for this font family. Please switch to a supported weight.`
                      }
                    />
                  </MenuItemOption>
                )
              }
              return (
                <MenuItemOption key={weight} value={weight.toString()}>
                  <Text fontSize="lg">
                    {WEIGHTS_MAP[weight] ?? defaultWeightName}
                  </Text>
                </MenuItemOption>
              )
            })}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
      {isDeletedCustomFontWeight && (
        <Text fontSize="xs" mt={1.5}>
          <Link href={fontUrl} textDecoration="underline" isExternal>
            Manage font files
            <ExternalLinkIcon ml={1} />
          </Link>
        </Text>
      )}
    </>
  )
}
